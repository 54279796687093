.experience {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.experience__content {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(508px, 1fr) 1fr;
    grid-template-rows: max-content;
    column-gap: 30px;
    align-items: center;
    margin: 24px 0 0;
}

.experience__banner {
    width: 100%;
    aspect-ratio: 572 / 476;
    object-fit: contain;
}



.experience__texts-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.experience__texts-title {
    color: #0AB0BB;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 172%;
}

.experience__texts-list {
    margin: 34px 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.experience__texts-list-item {
    width: 100%;
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-template-rows: max-content;
    column-gap: 20px;
    align-items: center;
}

.experience__texts-list-item-bulet {
    width: 100%;
    aspect-ratio: 1 / 1;

}

.experience__texts-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 172%;
    color: #000000;
}



@media (max-width: 1000px) {

    .experience__content {
        align-items: center;
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 820px) {
    .experience__content {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .experience__texts-container {
        align-items: center;
        max-width: 525px;
    }

    .experience__banner {
        max-width: 400px;
    }

    .experience__texts {
        grid-template-columns: 8px 1fr;
    }

    .experience__texts-title {
        font-size: 18px;
    }

    .experience__texts-text {
        font-size: 16px;
    }

}

@media (max-width: 530px) {
    .experience__texts {
        grid-template-columns: 1fr;
    }

    .experience__line {
        display: none;
    }

    .experience__texts-title {
        font-size: 16px;
    }

    .experience__texts-text {
        font-size: 14px;
    }

}