.stats {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.stats__container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr minmax(508px, 1fr);
    grid-template-rows: max-content;
    column-gap: 40px;
    align-items: center;
}

.stats__container_2 {
    grid-template-columns: minmax(508px, 1fr) 1fr;
}

.stats__banner {
    width: 100%;
    aspect-ratio: 580 / 340;
    object-fit: contain;
}

.stats__banner-2{
    width: 100%;
    aspect-ratio: 476 / 540;
    object-fit: contain;
}

.stats__texts {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.stats__title {
    color: #047597;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    line-height: 105%;
}

.stats__text {
    margin: 30px 0 0;
    width: 100%;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.stats__subtitle {
    color: #047597;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 105%;
}

.stats__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 50px 0 0;
    gap: 40px;
}

.stats__list-item {
    display: grid;
    width: 100%;
    grid-template-columns: 24px 1fr;
    grid-template-rows: max-content;
    column-gap: 20px;
    align-items: start;
}

.stats__list-item-texts {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 6px;
}


.stats__list-item-title {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
}

.stats__list-item-text {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 122%;
}

.stats__list-item-bulet{
    width: 100%;
    aspect-ratio: 1 / 1;
}

@media (max-width: 1000px) {
    .stats__title {
        font-size: 32px;
    }

    .stats__subtitle{
        font-size: 32px;
    }

    .stats__container {
        align-items: center;
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 820px) {
    .stats__container {
        display: flex;
        flex-direction: column-reverse;
        gap: 32px;
    }

    .stats__container_2 {
        flex-direction: column;
    }

    .stats__banner {
        max-width: 400px;
    }
    .stats__banner-2{
        max-width: 400px;
    }



    .stats__text {
        margin: 20px 0 0;
        font-size: 16px;
    }

    .stats__list-item-text{
        font-size: 16px;
    }

    .stats__list-item-title{
        font-size: 20px;
    }


}

@media (max-width: 530px) {
    .stats__title {
        font-size: 24px;
    }
    .stats__subtitle{
        font-size: 24px;
    }

    .stats__text {
        font-size: 14px;
        margin: 16px 0 0;
    }
    .stats__list-item-text{
        font-size: 14px;
    }
    .stats__list-item-title{
        font-size: 18px;
    }

}