.about-us {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-us__title {
    color: #0AB0BB;
    font-size: 52px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    /* 107.692% */
    text-transform: uppercase;
}

.about-us__content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr minmax(508px, 1fr);
    grid-template-rows: max-content;
    column-gap: 30px;
    align-items: end;
    margin: 24px 0 0;
}

.about-us__banner {
    width: 100%;
    aspect-ratio: 572 / 476;
    object-fit: contain;
}

.about-us__texts {
    width: 100%;
    display: grid;
    grid-template-columns: 12px 1fr;
    grid-template-rows: max-content;
    box-sizing: border-box;
    column-gap: 36px;
    padding-bottom: 12px;
    position: relative;
}

.about-us__line {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: #93D4D2;
    position: relative;
    z-index: 2;
}

.about-us__texts-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
}

.about-us__texts-title {
    color: #0AB0BB;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 172%;
}

.about-us__texts-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 172%;
    color: #000000;
}

.about-us__texts-text_1 {
    margin: 32px 0 0;
}

.about-us__texts-text_2 {
    margin: 28px 0 0;
}

.about-us__bg-icon {
    width: 85px;
    height: 96px;
    position: absolute;
    z-index: 0;
    right: 24px;
    bottom: 44px;
    opacity: 0.3;
}

@media (max-width: 1000px) {
    .about-us__title {
        font-size: 32px;
    }

    .about-us__content {
        align-items: center;
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 820px) {
    .about-us__content {
        display: flex;
        flex-direction: column-reverse;
        gap: 32px;
    }

    .about-us__banner {
        max-width: 400px;
    }

    .about-us__texts {
        grid-template-columns: 8px 1fr;
    }

    .about-us__texts-title {
        font-size: 18px;
    }

    .about-us__texts-text {
        font-size: 16px;
    }

    .about-us__texts-text_1 {
        margin-top: 18px;
    }

    .about-us__texts-text_2 {
        margin-top: 16px;
    }
}

@media (max-width: 530px) {
    .about-us__title{
        font-size: 24px;
    }
    .about-us__texts {
        grid-template-columns: 1fr;
    }

    .about-us__line {
        display: none;
    }

    .about-us__texts-title {
        font-size: 16px;
    }

    .about-us__texts-text {
        font-size: 14px;
    }

    .about-us__texts-text_1 {
        margin-top: 16px;
    }

    .about-us__texts-text_2 {
        margin-top: 14px;
    }
}